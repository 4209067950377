<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <Header />
    <section class="py-5 unList-bestSeller">
      <section class="un-section-seller margin-t-20 container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="car bg-light rounded-10">
              <div class="card-body">
                <h5 class="fw-bold">How to submit dApps</h5>
                <ol class="mt-3">
                  <li>
                    <p>Fill all information about your dApps project <br /></p>
                  </li>
                  <li>
                    <p>
                      Add icon, and banner images with standard type <br />
                      <b>
                        (max images size is 2mb, you must compress before
                        upload)<br />
                      </b>
                    </p>
                  </li>
                  <li>
                    <p>Fill token contract if dapps have token<br /></p>
                  </li>
                  <li>
                    <p>Fill social media stuff<br /></p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <form
              @submit.prevent="createProduct()"
              enctype="multipart/form-data"
            >
              <div class="modal-body">
                <div class="form-group">
                  <label>dApps Name <span class="text-danger">*</span></label>
                  <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('name') }"
                    placeholder="Your dApps Name"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Slug (from your dapps name but change space with "-", and
                    must be lowercase) <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.slug"
                    type="text"
                    name="slug"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('slug') }"
                    placeholder="your-dapps-name"
                  />
                  <has-error :form="form" field="slug"></has-error>
                </div>
                <div class="form-group">
                  <label>Token Contract</label>
                  <div
                    class="card card-body bg-light rounded-10 mt-3 network"
                    v-for="(tc, counter) in form.token_contract"
                    v-bind:key="counter"
                  >
                    <div class="d-flex justify-content-end">
                      <div
                        class="btn btn-xs-size btn-danger text-white"
                        @click="deleteNetwork(counter)"
                      >
                        <em class="ri ri-close-line"></em>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-1 fw-bold">{{ counter + 1 }}.</div>
                      <div class="col-11">
                        <label for="duration fw-bold mb-1">Network:</label>
                        <select
                          name=""
                          id=""
                          v-model.lazy="tc.network"
                          class="form-control"
                        >
                          <option
                            :value="network.name"
                            v-for="(network, index) in contractNetwork"
                            :key="index"
                          >
                            {{ network.name }}
                          </option>
                        </select>
                        <div class="card rounded-10 mt-2">
                          <div class="card-body">
                            <label for="duration fw-bold mb-2"
                              >Contract Address:</label
                            >
                            <input
                              type="text"
                              v-model.lazy="tc.contracts[0].contract"
                              class="form-control mb-3"
                            />
                            <label for="duration fw-bold mb-2"
                              >Is Token Contract Address:</label
                            >
                            <select
                              name=""
                              id=""
                              v-model.lazy="tc.contracts[0].is_token_contract"
                              class="form-control"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div class="card rounded-10 mt-2">
                          <div class="card-body">
                            <label for="duration fw-bold mb-2"
                              >Contract Address:</label
                            >
                            <input
                              type="text"
                              v-model.lazy="tc.contracts[1].contract"
                              class="form-control mb-3"
                            />
                            <label for="duration fw-bold mb-2"
                              >Is Token Contract Address:</label
                            >
                            <select
                              name=""
                              id=""
                              v-model.lazy="tc.contracts[1].is_token_contract"
                              class="form-control"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div class="card rounded-10 mt-2">
                          <div class="card-body">
                            <label for="duration fw-bold mb-2"
                              >Contract Address:</label
                            >
                            <input
                              type="text"
                              v-model.lazy="tc.contracts[2].contract"
                              class="form-control mb-3"
                            />
                            <label for="duration fw-bold mb-2"
                              >Is Token Contract Address:</label
                            >
                            <select
                              name=""
                              id=""
                              v-model.lazy="tc.contracts[2].is_token_contract"
                              class="form-control"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="btn btn-primary mt-3"
                    @click="addNetwork"
                  >
                    <em class="ri ri-add-line"></em> Add More Network
                  </button>
                </div>
                <div class="form-group">
                  <label>Tag Line <span class="text-danger">*</span></label>
                  <input
                    v-model="form.tag_line"
                    type="text"
                    name="tag_line"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('tag_line') }"
                    placeholder="This dapps is insane dapps"
                  />
                  <has-error :form="form" field="tag_line"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Short Description <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.short_description"
                    type="text"
                    name="short_description"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('short_description'),
                    }"
                    placeholder="Short description about your dapps"
                  />
                  <has-error :form="form" field="short_description"></has-error>
                </div>
                <div class="form-group">
                  <label>Description <span class="text-danger">*</span></label>
                  <input
                    v-model="form.description"
                    type="text"
                    name="description"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('description') }"
                    placeholder="Description about your dapps"
                  />
                  <has-error :form="form" field="description"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Submitted At (Year-month-day, example: 2022-12-31)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.submitted_at"
                    type="text"
                    name="submitted_at"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('submitted_at') }"
                    placeholder="Example: 2022-12-31"
                  />
                  <has-error :form="form" field="submitted_at"></has-error>
                </div>
                <div class="form-group">
                  <label>Dapps Url <span class="text-danger">*</span></label>
                  <input
                    v-model="form.dapps_url"
                    type="text"
                    name="dapps_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('dapps_url') }"
                    placeholder="https://yourdapps.com"
                  />
                  <has-error :form="form" field="dapps_url"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Is Trending</label>
                  <input
                    v-model="form.is_trending"
                    type="text"
                    value="0"
                    name="is_trending"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_trending') }"
                  />
                  <has-error :form="form" field="is_trending"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Is New</label>
                  <input
                    v-model="form.is_new"
                    type="text"
                    value="1"
                    name="is_new"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_new') }"
                  />
                  <has-error :form="form" field="is_new"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Is Ads</label>
                  <input
                    v-model="form.is_ads"
                    type="text"
                    name="is_ads"
                    value="0"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_ads') }"
                  />
                  <has-error :form="form" field="is_ads"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Is Show</label>
                  <input
                    v-model="form.is_show"
                    type="text"
                    value="0"
                    name="is_show"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('is_show') }"
                  />
                  <has-error :form="form" field="is_show"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Position</label>
                  <input
                    v-model="form.position"
                    type="text"
                    value="1"
                    name="position"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('position') }"
                  />
                  <has-error :form="form" field="position"></has-error>
                </div>
                <div class="form-group d-none">
                  <label>Like</label>
                  <input
                    v-model="form.like"
                    type="text"
                    value="1"
                    name="like"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('like') }"
                  />
                  <has-error :form="form" field="like"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >dApps Icon (Only jpg, png, webp)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    @change="fileImage"
                    name="icon_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('icon_url') }"
                  />
                  <has-error :form="form" field="icon_url"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Banner One (Only jpg, png, webp)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    @change="fileImageBannerOne"
                    name="banner_one_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('banner_one_url') }"
                  />
                  <has-error :form="form" field="banner_one_url"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Banner Two (Only jpg, png, webp)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    @change="fileImageBannerTwo"
                    name="banner_two_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('banner_two_url') }"
                  />
                  <has-error :form="form" field="banner_two_url"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Banner Three (Only jpg, png, webp)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="file"
                    ref="file"
                    id="file"
                    @change="fileImageBannerThree"
                    name="banner_three_url"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('banner_three_url'),
                    }"
                  />
                  <has-error :form="form" field="banner_three_url"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Tag Banner One (Fill with tags about image banner one,
                    Example: Home UI ,Or Staking Page)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.banner_one_text"
                    type="text"
                    name="banner_one_text"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('banner_one_text'),
                    }"
                    placeholder="Home UI"
                  />
                  <has-error :form="form" field="banner_one_text"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Tag Banner Two (Fill with tags about image banner one,
                    Example: Home UI ,Or Staking Page)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.banner_two_text"
                    type="text"
                    name="banner_two_text"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('banner_two_text'),
                    }"
                    placeholder="Staking UI"
                  />
                  <has-error :form="form" field="banner_two_text"></has-error>
                </div>
                <div class="form-group">
                  <label
                    >Tag Banner Three (Fill with tags about image banner one,
                    Example: Home UI ,Or Staking Page)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="form.banner_three_text"
                    type="text"
                    name="banner_three_text"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('banner_three_text'),
                    }"
                    placeholder="Swap UI"
                  />
                  <has-error :form="form" field="banner_three_text"></has-error>
                </div>
                <div class="form-group">
                  <label>Youtube Banner Url (optional)</label>
                  <input
                    v-model="form.youtube_banner_url"
                    type="text"
                    name="youtube_banner_url"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('youtube_banner_url'),
                    }"
                    placeholder="Youtube url banner"
                  />
                  <has-error
                    :form="form"
                    field="youtube_banner_url"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label>Youtube Banner Title (optional)</label>
                  <input
                    v-model="form.youtube_banner_title"
                    type="text"
                    name="youtube_banner_title"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('youtube_banner_title'),
                    }"
                    placeholder="Youtube Banner title"
                  />
                  <has-error
                    :form="form"
                    field="youtube_banner_title"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label>Youtube Banner Slug (optional)</label>
                  <input
                    v-model="form.youtube_banner_slug"
                    type="text"
                    name="youtube_banner_slug"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('youtube_banner_slug'),
                    }"
                    placeholder="Youtube Banner slug"
                  />
                  <has-error
                    :form="form"
                    field="youtube_banner_slug"
                  ></has-error>
                </div>
                <div class="form-group">
                  <label>Twitter Url (optional)</label>
                  <input
                    v-model="form.twitter_url"
                    type="text"
                    name="twitter_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('twitter_url') }"
                    placeholder="https://twitter.com/yourdapps"
                  />
                  <has-error :form="form" field="twitter_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Facebook Url (optional)</label>
                  <input
                    v-model="form.facebook_url"
                    type="text"
                    name="facebook_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('facebook_url') }"
                    placeholder="https://facebook.com/yourdapps"
                  />
                  <has-error :form="form" field="facebook_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Github Url (optional)</label>
                  <input
                    v-model="form.github_url"
                    type="text"
                    name="github_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('github_url') }"
                    placeholder="https://github.com/yourdapps"
                  />
                  <has-error :form="form" field="github_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Instagram Url (optional)</label>
                  <input
                    v-model="form.instagram_url"
                    type="text"
                    name="instagram_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('instagram_url') }"
                    placeholder="https://instagram.com/@yourdapps"
                  />
                  <has-error :form="form" field="instagram_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Youtube Url (optional)</label>
                  <input
                    v-model="form.youtube_url"
                    type="text"
                    name="youtube_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('youtube_url') }"
                    placeholder="https://youtube.com/yourdapps"
                  />
                  <has-error :form="form" field="youtube_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Discord Url (optional)</label>
                  <input
                    v-model="form.discord_url"
                    type="text"
                    name="discord_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('discord_url') }"
                    placeholder="https://discord.gg/yourdapps"
                  />
                  <has-error :form="form" field="discord_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Medium Url (optional)</label>
                  <input
                    v-model="form.medium_url"
                    type="text"
                    name="medium_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('medium_url') }"
                    placeholder="Medium URL"
                  />
                  <has-error :form="form" field="medium_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Reddit Url (optional)</label>
                  <input
                    v-model="form.reddit_url"
                    type="text"
                    name="reddit_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('reddit_url') }"
                    placeholder="Reddit URL"
                  />
                  <has-error :form="form" field="reddit_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Telegram Url (optional)</label>
                  <input
                    v-model="form.telegram_url"
                    type="text"
                    name="telegram_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('telegram_url') }"
                    placeholder="https://t.me/yourdapps"
                  />
                  <has-error :form="form" field="telegram_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Coinmarketcap Url (optional)</label>
                  <input
                    v-model="form.coinmarketcap_url"
                    type="text"
                    name="coinmarketcap_url"
                    class="form-control"
                    :class="{
                      'is-invalid': form.errors.has('coinmarketcap_url'),
                    }"
                    placeholder="CMC URL"
                  />
                  <has-error :form="form" field="coinmarketcap_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Coingecko Url (optional)</label>
                  <input
                    v-model="form.coingecko_url"
                    type="text"
                    name="coingecko_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('coingecko_url') }"
                    placeholder="CG URL"
                  />
                  <has-error :form="form" field="coingecko_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Audit Url (optional)</label>
                  <input
                    v-model="form.audit_url"
                    type="text"
                    name="audit_url"
                    class="form-control"
                    :class="{ 'is-invalid': form.errors.has('audit_url') }"
                    placeholder="Audit URL"
                  />
                  <has-error :form="form" field="audit_url"></has-error>
                </div>
                <div class="form-group">
                  <label>Categories (Type query and choose)</label>
                  <vue-tags-input
                    v-model="form.category"
                    :tags="form.categorys"
                    :autocomplete-items="filteredItemsCate"
                    @tags-changed="
                      (newCategorys) => (form.categorys = newCategorys)
                    "
                  />
                  <has-error :form="form" field="categorys"></has-error>
                </div>
                <div class="form-group">
                  <label>Networks (Type query and choose)</label>
                  <vue-tags-input
                    v-model="form.network"
                    :tags="form.networks"
                    :autocomplete-items="filteredItems"
                    @tags-changed="
                      (newNetworks) => (form.networks = newNetworks)
                    "
                  />
                  <has-error :form="form" field="networks"></has-error>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">
                  Submit Your Dapps
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import Form from "vform";
import axios from "axios";

export default {
  name: "AddDapps",
  components: { Header, Footer, VueTagsInput },
  data() {
    return {
      form: new Form({
        id: "",
        name: "",
        slug: "",
        token_contract: [
          {
            network: "BNB Chain",
            contracts: [
              {
                contract: "",
                is_token_contract: true,
              },
              {
                contract: "",
                is_token_contract: false,
              },
              {
                contract: "",
                is_token_contract: false,
              },
            ],
          },
        ],
        tagline: "",
        short_description: "",
        description: "",
        submitted_at: "",
        dapps_url: "",
        is_trending: 0,
        is_new: 1,
        is_ads: 0,
        is_show: 0,
        position: "1",
        like: "1",
        icon_url: "",
        banner_one_url: "",
        banner_two_url: "",
        banner_three_url: "",
        banner_one_text: "",
        banner_two_text: "",
        banner_three_text: "",
        youtube_banner_url: "",
        youtube_banner_title: "",
        youtube_banner_slug: "",
        twitter_url: "",
        facebook_url: "",
        github_url: "",
        instagram_url: "",
        youtube_url: "",
        discord_url: "",
        medium_url: "",
        reddit_url: "",
        telegram_url: "",
        coinmarketcap_url: "",
        coingecko_url: "",
        audit_url: "",
        networks: [],
        categorys: [],
      }),
      category: "",
      contractNetwork: "",
      network: "",
      autocompleteItems: [],
      autocompleteItemsCate: [],
    };
  },
  methods: {
    loadCategories() {
      axios
        .get(process.env.VUE_APP_API + "api/category/list")
        .then((response) => {
          this.autocompleteItemsCate = response.data.data.map((d) => {
            return { text: d.name, id: d.id };
          });
        })
        .catch(() => console.warn("Oh. Something went wrong"));
    },
    loadNetworks() {
      axios
        .get(process.env.VUE_APP_API + "api/network/list")
        .then((response) => {
          this.contractNetwork = response.data.data;
          this.autocompleteItems = response.data.data.map((a) => {
            return { text: a.name, id: a.id };
          });
        })
        .catch(() => console.warn("Oh. Something went wrong"));
    },
    fileImage(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 2111775) {
        reader.onloadend = (event) => {
          //console.log('RESULT', reader.result)
          this.form.icon_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size can not be bigger than 2 MB");
      }
    },
    fileImageBannerOne(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 2111775) {
        reader.onloadend = (event) => {
          //console.log('RESULT', reader.result)
          this.form.banner_one_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size can not be bigger than 2 MB");
      }
    },
    fileImageBannerTwo(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 2111775) {
        reader.onloadend = (event) => {
          //console.log('RESULT', reader.result)
          this.form.banner_two_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size can not be bigger than 2 MB");
      }
    },
    fileImageBannerThree(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 2111775) {
        reader.onloadend = (event) => {
          //console.log('RESULT', reader.result)
          this.form.banner_three_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size can not be bigger than 2 MB");
      }
    },
    createProduct() {
      this.$Progress.start();

      axios
        .post(process.env.VUE_APP_API + "api/product/add-dapps", this.form)
        .then((data) => {
          if (data.data.success) {
            this.$swal({
              icon: "success",
              title: data.data.message,
            });
            this.$Progress.finish();
          } else {
            this.$swal({
              icon: "error",
              title: "Some error occured! Please try again",
            });

            this.$Progress.failed();
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Some error occured! Please try again",
          });
        });
    },
    addNetwork() {
      this.form.token_contract.push({
        network: "ETH",
        contracts: [
          {
            contract: "",
            is_token_contract: true,
          },
          {
            contract: "",
            is_token_contract: false,
          },
          {
            contract: "",
            is_token_contract: false,
          },
        ],
      });
    },
    deleteNetwork(counter) {
      this.form.token_contract.splice(counter, 1);
    },
  },
  created() {
    this.$Progress.start();

    this.loadCategories();
    this.loadNetworks();

    this.$Progress.finish();
  },
  computed: {
    filteredItemsCate() {
      return this.autocompleteItemsCate.filter((c) => {
        return c.text.toLowerCase().indexOf(this.category.toLowerCase()) !== -1;
      });
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.network.toLowerCase()) !== -1;
      });
    },
  },
};
</script>

<style>
</style>